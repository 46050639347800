class HomeOfficePage extends Component {

    static  name() {
        return "HomeOfficePage";
    }

    static componentName() {
        return "HomeOfficePage";
    }

    getTemplate() {
        return `<div class="static-page">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="section-content container">
                        <div class="section-header text-center">
                            <img src="/static/ecommerce/img/home_office_banner.jpeg" class="img-fluid" alt="" />
                        </div>
                        <div class="row mt-5">
                            <div class="col">
                                <div>
                                    <p>Actualmente Ofisnack es el concepto más innovador para los colaboradores que realizan home office, ya sea de forma permanente, o de manera flexible.</p>
                                    <p>¿De qué se trata? Todos los meses, el colaborador recibe en su casa una caja con una gran variedad de snacks saludables, nutritivos y ricos, a través de una 
                                    suscripción mensual que realiza la empresa. Además, la contratación del servicio incluye el reemplazo del mix mensual, por un nuevo mix todos los meses, esto 
                                    hace que el concepto sea más divertido. La idea es que el colaborador nunca se canse o aburra de comer snacks saludables, sino por el contrario, que se sorprenda 
                                    con el nuevo surtido.</p>
                                    <p>Este servicio logra, por un lado, que la empresa se asegure que su colaborador se alimenta con snacks saludables, naturales y ricos durante los breaks, por el 
                                    otro, el colaborador se siente valorado por la empresa y esto resulta ser un gran motivador de los equipos de trabajo. Si el personal se siente cuidado se logrará 
                                    un mayor compromiso y fidelización del colaborador. Ofisnack es ideal para aquellas empresas que buscan retener a los mejores colaboradores.</p>
                                    <p>Si estás interesado en que la empresa donde trabajás adquiera este servicio, completá el formulario y nos pondremos en contacto con ustedes para darte más detalles 
                                    de Ofisnack.</p>
                                    <div class="text-right">
                                        <a class="btn btn-custom btn-form" target="_blank" href="https://www.ofidirect.com/ecommerce/#/contactus">Completá el Formulario</a>
                                    </div>

                                    <p class="big-quote mt-5 px-5 text-center">
                                        <span><img src="/static/ecommerce/img/cuote-open-blue.png" width="38" class="img-fluid" alt="" /></span>
                                        Ofisnack es ideal para aquellas empresas que buscan retener a los mejores colaboradores. 
                                        <span><img src="/static/ecommerce/img/cuote-close-blue.png" width="38" class="img-fluid" alt="" /></span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 text-right">
                                <img src="/static/ecommerce/img/home_office_img_lateral.jpeg" class="img-fluid mb-3" alt="" />
                                <img src="/static/ecommerce/img/home_office_img_lateral2.jpeg" class="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

HomeOfficePage.registerComponent();